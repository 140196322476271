import { mobile, tablet } from "components/templates/Breakpoints";
import { StandardPadding } from "components/templates/StandardPadding";
import styled from "styled-components";

export const OpenRolesSection = styled.section`
  background: #f7f3ff;
`;

export const GlassDoorSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5rem;

  ${tablet} {
    padding-top: 3rem;
  }
`;

export const Padding = styled(StandardPadding)`
  position: relative;
  max-width: 80rem;

  @media only screen and (max-width: 40rem) {
    overflow: hidden;
  }
`;

export const BenefitCardWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const JobCardWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22.375rem, 1fr));
  gap: 2.625rem;
  padding: 3.75rem 0 2.5rem;

  ${mobile} {
    padding: 2rem 0 0;
  }
`;
