import { FC } from "react";
import styled from "styled-components";
import { Link } from "@components/atoms";
import { color } from "../../styles";
import { mobile, tablet } from "../templates/Breakpoints";
import { useBreakpoints } from "hooks";
import { BetterButton } from "components/atoms/BetterButton";
import { ClientOnly } from "@components/templates";
import { StaticRoutes } from "@routes";
import Image from "next/image";

export interface CareersCTAProps {
  title?: string;
  subtitle?: string;
  buttonText?: string;
  buttonLink?: string;
}

export const CareersCTA: FC<CareersCTAProps> = (props) => (
  <ClientOnly>
    <_CareersCTA {...props} />
  </ClientOnly>
);

export const _CareersCTA: FC<CareersCTAProps> = ({
  title = "Don’t see any vacancies?",
  subtitle = "Don’t see any vacancies? If there isn’t a role here for you, but you genuinely care about the same things we do, we still want to hear from you! Please send a copy of your CV and an introduction letter to hello@yulife.com and let’s talk.",
  buttonText = "Get in touch",
  buttonLink = StaticRoutes.getAQuote,
}) => {
  const { isMobile } = useBreakpoints();

  return (
    <CallbackWrap>
      <Background>
        <TextBlock>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Link href={buttonLink} legacyBehavior>
            <BetterButton
              variant="primary"
              text={buttonText}
              width={isMobile ? "100%" : "fit-content"}
            />
          </Link>
        </TextBlock>
        <YugiWrapper>
          <Img
            height={352}
            width={338}
            src="/static/giraffes/YugiWorkingFromHome.svg"
            alt="Yugi working from his home office"
          />
        </YugiWrapper>
      </Background>
    </CallbackWrap>
  );
};

const Background = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 65rem;
  background: ${color.S320};
  border-radius: 0.5rem;
  border: 1px solid ${color.S3100};
  overflow: hidden;

  ${mobile} {
    border-radius: 0;
    display: block;
  }
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 2.5rem 0 2.5rem 2.5rem;

  ${mobile} {
    margin-right: 2.5rem;
  }
`;

const Title = styled.h2`
  letter-spacing: 0;
  font-size: 2.5rem;
  margin: 0;
  max-width: 32rem;

  ${mobile} {
    width: 100%;
    font-size: 2rem;
  }
`;

const Subtitle = styled.p`
  max-width: 33.8rem;
  font-size: 1.25rem;
  letter-spacing: 1.245px;
  margin: 0.938rem 0 2rem;

  @media only screen and (max-width: 50rem) {
    max-width: 40rem;
  }
`;

const Img = styled(Image)`
  margin-right: 1.5rem;

  ${tablet} {
    transform: scale(0.9);
    margin-right: 1rem;
    margin-bottom: -1.2rem;
  }

  @media only screen and (max-width: 45rem) {
    display: none;
  }
`;

const YugiWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

export const CallbackWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 2rem 8rem;

  ${tablet} {
    padding: 2rem 2rem;
  }
`;
