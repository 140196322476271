import { FC } from "react";
import styled from "styled-components";
import { mobile } from "../templates/Breakpoints";

export interface CloudProps {
  id?: string;
  color?: string;
  scale?: number;
  reversed?: boolean;
  hideOnMobile?: boolean;
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  animated?: boolean;
  animationTime?: string;
  animationDistance?: string;
  animationDirection?: string;
}

enum CloudColors {
  "yellow" = "#FFF3B2",
  "light-yellow" = "#FFF7CC",
  "purple" = "#EAE1FF",
  "green" = "#CAF8E8",
  "blue" = "#CAEFF9",
}

export const Cloud: FC<CloudProps> = ({
  id = "cloud",
  color = "yellow",
  scale = 1,
  ...svgProps
}) => (
  <SVG
    id={id}
    width={140 * scale}
    height={40 * scale}
    viewBox="0 0 140 40"
    scale={scale}
    {...svgProps}
  >
    <path
      d="M2.342 40h135.316c2.053 0 3.115-2.545 1.686-4.06-4.308-4.55-12.286-11.437-21.832-12.557-14.456-1.691-25.953-2.038-31.206-5.76C81.052 13.904 66.496 0 46.552 0c-7.766 0-14.128 6.782-17.086 13.556-2.959 6.782-7.556 9.15-15.112 11.527C9.601 26.573 4.067 32.098.617 35.989-.75 37.527.312 40 2.342 40z"
      fill={CloudColors[color] || color}
    />
  </SVG>
);

const SVG = styled.svg<{
  id: string;
  scale: number;
  reversed?: boolean;
  hideOnMobile?: boolean;
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  animated?: boolean;
  animationTime?: string;
  animationDistance?: string;
  animationDirection?: string;
}>`
  ${(p) =>
    p.hideOnMobile
      ? `display: block;
        ${mobile} {
            display: none;
        }`
      : ""};

  position: absolute;
  ${(p) => (p.top ? `top: ${p.top}` : "")};
  ${(p) => (p.left ? `left: ${p.left}` : "")};
  ${(p) => (p.bottom ? `bottom: ${p.bottom}` : "")};
  ${(p) => (p.right ? `right: ${p.right}` : "")};

  ${(p) =>
    p.animated
      ? `animation: cloud-sway-${p.id} ${p.animationTime || `${10 + 4 * p.scale}s`} linear infinite
      ${p.animationDirection || p.reversed ? "alternate-reverse" : "alternate"};
        
        @keyframes cloud-sway-${p.id} {
            0% {
              transform: translateX(${p.animationDistance || `${10 * p.scale}rem)`} 
              ${p.reversed ? "scale(-1, 1)" : ""};
            }
            100% {
              transform: translateX(0rem) 
              ${p.reversed ? "scale(-1, 1)" : ""};
            }
          }
        `
      : ""};
`;
