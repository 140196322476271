import { FC, PropsWithChildren, ReactElement } from "react";
import styled from "styled-components";
import { color } from "../../styles";
import { H1 } from "../atoms/Heading";
import { P1 } from "../atoms/Paragraph";
import { BetterButton } from "../atoms/BetterButton";
import { Link } from "@components/atoms";
import { NotMobile } from "components/templates/Breakpoints";
import { mobile, tablet } from "components/templates/Breakpoints";
import { NewStatSection, NewStatSectionProps } from "components/molecules/NewStatSection";
import { StaticRoutes } from "@routes";
import { Spacer } from "components/templates/Spacer";
import Image from "next/image";
import { convertNewlinesToBreaks } from "utils/string";

type StatBarVariantType = "gray" | "purple" | "blue";
interface HeroSectionProps {
  eyeBrow?: string;
  eyeBrowColour?: string;
  title: string | JSX.Element | string[];
  subHeading?: string | ReactElement;
  buttonText?: string;
  buttonLink?: string;
  buttonLinkFunction?: VoidFunction;
  image?: string | ReactElement;
  alt?: string;
  backgroundColor: string;
  clouds?: JSX.Element;
  groundedImg?: boolean;
  background?: JSX.Element;
  textPt?: string;
  textColor?: string;
  imgPt?: string;
  statBar?: NewStatSectionProps;
  gap?: string;
  statBarVariant?: StatBarVariantType;
  additionalCopy?: JSX.Element;
  copyPadding?: string;
  copyMaxWidth?: string;
  titleMaxWidth?: string;
  titleFontSize?: [string, string, string];
  height?: string;
  lowerSection?: JSX.Element
}

const backgroundColorHash = (colorVariant: StatBarVariantType) => {
  switch (colorVariant) {
    case "blue":
      return color.S2100;
    case "purple":
      return color.S3100;
    case "gray":
    default:
      return color.B210;
  }
};

export const HeroSection: FC<PropsWithChildren<HeroSectionProps>> = ({
  eyeBrow,
  eyeBrowColour,
  title,
  subHeading,
  textColor,
  buttonText,
  buttonLinkFunction,
  buttonLink = buttonLinkFunction ? null : StaticRoutes.getAQuote,
  image,
  alt,
  backgroundColor,
  clouds,
  groundedImg = false,
  background,
  textPt,
  children,
  imgPt,
  statBar,
  statBarVariant,
  gap,
  additionalCopy,
  copyPadding,
  copyMaxWidth,
  titleMaxWidth,
  titleFontSize,
  height = "56.25rem",
  lowerSection
}) => {
  return (
    <>
      <Container height={height} backgroundColor={backgroundColor}>
        {clouds || null}
        {groundedImg && (
          <GroundedYugiWrapper>
            {typeof image === "string" ? (
              <YugiImage src={image} alt={alt} width={440} height={600} priority />
            ) : (
              image
            )}
          </GroundedYugiWrapper>
        )}
        <NotMobile>{background}</NotMobile>
        <Content $copyPadding={copyPadding} gap={gap}>
          <LeftContainer textPt={textPt}>
            {eyeBrow && (
              <Eyebrow color={eyeBrowColour ? eyeBrowColour : color.S3100}>{eyeBrow}</Eyebrow>
            )}
            <Title
              $titleFontSize={titleFontSize}
              textColor={textColor}
              titleMaxWidth={titleMaxWidth}
            >
              {convertNewlinesToBreaks(title)}
            </Title>
            {subHeading && (
              <SubHeading $copyMaxWidth={copyMaxWidth} textColor={textColor}>
                {convertNewlinesToBreaks(subHeading)}
              </SubHeading>
            )}
            <Spacer height="2.5rem" />
            {additionalCopy && additionalCopy}
            {buttonText && buttonLink && (
              <>
                <Link href={buttonLink} legacyBehavior>
                  <BetterButton text={buttonText} />
                </Link>
              </>
            )}
            {buttonText && buttonLinkFunction && (
              <div onClick={() => buttonLinkFunction()}>
                <BetterButton text={buttonText} />
              </div>
            )}
            {lowerSection && (
              lowerSection
            )}
          </LeftContainer>
          <RightContainer imgPt={imgPt}>
            {groundedImg ? (
              <Block />
            ) : typeof image === "string" ? (
              <YugiImage src={image} alt={alt} width={440} height={600} priority />
            ) : (
              image
            )}
          </RightContainer>
        </Content>
        {children}
      </Container>
      {statBar && (
        <NewStatSection
          backgroundColor={statBarVariant ? backgroundColorHash(statBarVariant) : null}
          inverted={statBarVariant === "gray"}
          {...statBar}
        />
      )}
    </>
  );
};

// eslint-disable-next-line prettier/prettier
const Title = styled(H1)<{
  textColor?: string;
  titleMaxWidth?: string;
  $titleFontSize: [string, string, string];
}>`
  max-width: ${(p) => p.titleMaxWidth || "40rem"};
  color: ${(p) => p.textColor || color.H100};
  font-size: ${(p) => p.$titleFontSize?.[0]};

  ${tablet} {
    font-size: ${(p) => p.$titleFontSize?.[1]};
  }

  ${mobile} {
    font-size: ${(p) => p.$titleFontSize?.[2]};
  }
`;

const Container = styled.div<{ backgroundColor: string; height: string }>`
  background: ${(p) => p.backgroundColor};
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: ${(p) => p.height};
  position: relative;
  overflow: hidden;
  padding-left: 2rem;

  ${tablet} {
    padding: 0;
  }

  ${mobile} {
    height: auto;
  }
`;

const Content = styled.div<{ gap?: string; $copyPadding?: string }>`
  display: flex;
  justify-content: center;
  min-height: 31.25rem;
  gap: ${(p) => (p.gap ? p.gap : "6vw")};
  padding: ${(p) => (p.$copyPadding ? p.$copyPadding : "6.5rem 1rem 8rem 1rem")};
  margin-top: 5rem;

  ${tablet} {
    padding: ${(p) => (p.$copyPadding ? p.$copyPadding : "7.5rem 2rem 4rem 2rem")};
    margin-top: 2rem;
  }

  ${mobile} {
    position: relative;
    padding: ${(p) => (p.$copyPadding ? p.$copyPadding : "6.5rem 2.5rem 5.5rem 2.5rem")};
    min-height: auto;
  }
`;

const LeftContainer = styled.div<{ textPt?: string }>`
  padding-top: ${(p) => p.textPt || "6rem"};
  z-index: 1;

  ${mobile} {
    padding-top: 0;
  }
`;

const RightContainer = styled.div<{ imgPt?: string }>`
  padding-top: ${(p) => p.imgPt || 0};

  @media only screen and (max-width: 60rem) {
    display: none;
  }
`;

const YugiImage = styled(Image)`
  max-width: 27.5rem;
  max-height: 37.5rem;
  position: relative;

  @media only screen and (max-width: 70rem) {
    height: 32.5rem;
    width: 23.75rem;
    margin-top: 2rem;
  }

  ${tablet} {
    height: 28rem;
    width: 21rem;
    margin-top: 2rem;
  }

  @media only screen and (max-width: 55rem) {
    height: 22rem;
    width: 16.5rem;
    margin-top: 5rem;
  }
`;

const Eyebrow = styled(P1)`
  font-weight: 700;
  padding-bottom: 0.5rem;
  padding-top: 0;
  font-size: 0.875rem;
  line-height: 0.875rem;
`;

const SubHeading = styled(P1)<{ textColor?: string; $copyMaxWidth?: string }>`
  max-width: ${(p) => p.$copyMaxWidth || "34rem"};
  color: ${(p) => p.textColor || color.B1100};
`;

const GroundedYugiWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  right: 10%;
  transform-origin: 50% 100%;
  z-index: 1;

  @media only screen and (max-width: 80rem) {
    transform: scale(0.9);
    right: 7%;
  }

  @media only screen and (max-width: 52rem) {
    display: none;
  }
`;

const Block = styled.div`
  width: 24rem;

  @media only screen and (max-width: 70rem) {
    width: 12rem;
  }

  ${tablet} {
    width: 7rem;
  }

  @media only screen and (max-width: 55rem) {
    width: 6rem;
  }
`;
