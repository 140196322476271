import { FC } from "react";
import { color as YU_COLOURS } from "@styles";

export const PlayButton: FC<
  React.PropsWithChildren<{
    color?: string;
  }>
> = ({ color = YU_COLOURS.white }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="100%"
      height="auto"
      viewBox="40.37 40.87 123.33 123.33"
    >
      <g filter="url(#filter0_d_8227_52868)">
        <path
          d="M94.6415 126.583L123.44 104.999C125.105 103.766 125.105 101.299 123.44 100.066L94.6415 78.4826C92.6065 76.941 89.7082 78.421 89.7082 80.9493V124.116C89.7082 126.644 92.6065 128.124 94.6415 126.583ZM102.042 40.866C68.0015 40.866 40.3749 68.4926 40.3749 102.533C40.3749 136.573 68.0015 164.199 102.042 164.199C136.082 164.199 163.708 136.573 163.708 102.533C163.708 68.4926 136.082 40.866 102.042 40.866ZM102.042 151.866C74.8465 151.866 52.7082 129.728 52.7082 102.533C52.7082 75.3376 74.8465 53.1993 102.042 53.1993C129.237 53.1993 151.375 75.3376 151.375 102.533C151.375 129.728 129.237 151.866 102.042 151.866Z"
          fill={color}
        />
      </g>
    </svg>
  );
};
