import { H2 } from "components/atoms/Heading";
import { mobile, NotMobile, tablet } from "components/templates/Breakpoints";
import { StandardSection } from "components/templates/StandardSection";
import { Vimeo } from "@services";
import { FC, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { color } from "../../styles";
import { P1 } from "components/atoms/Paragraph";
import { BetterButton } from "components/atoms/BetterButton";
import Link from "next/link";
import { PlayButton } from "components/atoms/PlayButton";
import Image from "next/image";

interface VideoPlayerSectionProps {
  title: string | JSX.Element;
  backgroundColor?: string;
  subtitle?: string;
  vimeoId: string;
  videoImg: string;
  altText: string;
  buttonText?: string;
  buttonLink?: string;
}

export const VideoPlayerSection: FC<VideoPlayerSectionProps> = ({
  title,
  vimeoId,
  videoImg,
  subtitle,
  backgroundColor,
  buttonText,
  buttonLink,
  altText,
}) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <VimeoWrapper>
        <Vimeo
          vimeoId={vimeoId}
          isOpen={isVideoOpen}
          onClose={() => {
            setIsVideoOpen(false);
          }}
        />
      </VimeoWrapper>
      <StandardSection>
        {typeof title === "string" ? <Title align="center">{title}</Title> : title}
        {subtitle ? (
          <P1 pl="16%" pr="16%" align="center">
            {subtitle}
          </P1>
        ) : null}
        <ThemeContext.Consumer>
          {(props) => {
            return (
              <ImageContainer
                onMouseEnter={() => {
                  props?.preloadVideo(vimeoId);
                }}
              >
                <VideoPreviewImg width={1120} height={622} src={videoImg} alt={altText} />
                <PlayButtonContainer
                  onClick={() => {
                    props?.triggerVideo();
                  }}
                >
                  <PlayButton />
                </PlayButtonContainer>
              </ImageContainer>
            );
          }}
        </ThemeContext.Consumer>
        {buttonText ? (
          <ButtonWrap>
            <Link href={buttonLink}>
              <BetterButton text={buttonText} />
            </Link>
          </ButtonWrap>
        ) : null}
      </StandardSection>
    </Wrapper>
  );
};

const VideoPreviewImg = styled(Image)`
  width: 100%;
  height: auto;
  margin-top: 4rem;

  ${mobile} {
    margin-top: 1.5rem;
  }
`;

const PlayButtonContainer = styled.div`
  display: flex;
  position: absolute;
  top: 42%;
  left: 43%;
  cursor: pointer;
  max-width: 11%;
`;

const Wrapper = styled.div<{ backgroundColor: string }>`
  background: ${(p) => p.backgroundColor || color.S310};

  ${tablet} {
    padding: 2.5rem 0;
  }

  ${mobile} {
    padding: 1rem 0;
  }
`;

const Title = styled(H2)`
  margin: 0 auto;
  font-size: 3.5rem;

  ${tablet} {
    font-size: 2.5rem;
  }
`;

export const VimeoWrapper = styled.div`
  position: absolute;
  top: 10.625rem;
  left: 3.4375rem;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;

  ${NotMobile} {
    svg {
      transition: 300ms ease-in-out;
    }

    &:hover {
      svg {
        transform: translateY(-4rem) scale(1.2);
      }
    }
  }
`;

const ButtonWrap = styled.div`
  padding-top: 4.9rem;
  margin: 0 auto;

  ${mobile} {
    padding-top: 1.5rem;
  }
`;
