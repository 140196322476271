interface QuoteSlide {
  quote: string;
  source: string;
  bigImg: string;
}

interface YucrewBenefitCard {
  iconName: string;
  title: string;
  description: string;
  justifyContent?: string;
}

export const quoteSlides: QuoteSlide[] = [
  {
    quote:
      "It’s incredible to be a part of a team that is using cutting-edge data science and AI to impact people's wellbeing. YuLife is revolutionising insurance.",
    source: "Dr. John Ronayne, Lead Data Scientist",
    bigImg: "/static/pages/careers/DrRonayne.png",
  },
  {
    quote:
      "I love YuLife's mission to get so much more from traditional insurance. It helps that I can sell a product I actually believe in and am inspired by.",
    source: "Jasmit Lall, SME Sales",
    bigImg: "/static/pages/careers/JasmitLall.png",
  },
  {
    quote:
      "I spent 20+ years in insurance trying to find a company that truly impacted employee health, and I found that in YuLife. I love the the real impact its brought to businesses.",
    source: "Keith Bale, Chief Revenue Officer",
    bigImg: "/static/pages/careers/KeithBale.png",
  },
  {
    quote:
      "As an ex-consultant, I love working on challenging problems that have real social impact - YuLife fit that bill perfectly. It is an amazing place to work.",
    source: "Amelia Carroll, Head of Corporate Strategy",
    bigImg: "/static/pages/careers/AmeliaCarroll.png",
  },
  {
    quote:
      "As an engineer, I appreciate that the team focuses on delivering high-quality work while also fostering a welcoming & fun workplace. That balance is rare!",
    source: "Halley Cummings, Software Engineer",
    bigImg: "/static/pages/careers/HalleyCummings.png",
  },
  {
    quote:
      "I can focus on my career without compromising time with my family at YuLife. I love that I can show my children that a company can do good for the world & its employees.",
    source: "Jodie Massingham, Account Strategy Manager",
    bigImg: "/static/pages/careers/JodieMassingham.png",
  },
];

export const yucrewBenefitCards: YucrewBenefitCard[] = [
  {
    iconName: "Certificate",
    title: "Insurance protection",
    description: "You’ll be protected, wherever you are based in the world!",
    justifyContent: "flex-start",
  },
  {
    iconName: "Chest",
    title: "Wellbeing rewards",
    description: "We actually reward you (up to £240 a year) for taking care of your wellbeing!",
    justifyContent: "flex-start",
  },
  {
    iconName: "Lotus",
    title: "Mental health support",
    description:
      "We take this very seriously and provide you access to a variety of services and resources.",
    justifyContent: "flex-start",
  },
  {
    iconName: "PolicySchedule",
    title: "Family-friendly leave",
    description:
      "16 weeks at full pay plus an additional six weeks leave per parent for children under five.",
  },
  {
    iconName: "Donate",
    title: "Learning and development",
    description:
      "Unlimited mentoring & coaching, bespoke manager training, plus a monthly learning allowance.",
  },
  {
    iconName: "Weekly",
    title: "Annual leave",
    description: "25 days, plus your local public holidays & a Love Being Yu day.",
    justifyContent: "flex-start",
  },
  {
    iconName: "Earth",
    title: "We support remote working!",
    description:
      "As long as it's within bounds, you can work from wherever you are, including our London Headquarters.",
    justifyContent: "flex-start",
  },
  {
    iconName: "Streak",
    title: "Events calendar",
    description: "Anything from yoga classes, HIIT, company parties, lunch & learns etc.",
  },
];
