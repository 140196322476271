import { FC } from "react";
import styled from "styled-components";
import { color } from "../../styles";
import { P1 } from "components/atoms/Paragraph";
import { Pill } from "components/atoms/Pill";
import { mobile, tablet } from "components/templates/Breakpoints";
import Link from "next/link";
import Image from "next/image";

export interface JobCardProps {
  title: string;
  description: string;
  department?: string;
  image: string;
  link: string;
  $cardMaxWidth?: string;
  $isHorizontal?: boolean;
}

export const JobCard: FC<JobCardProps> = ({
  image,
  $cardMaxWidth,
  department,
  title,
  description,
  $isHorizontal,
  link,
}) => {
  return (
    <>
      <Container $cardMaxWidth={$cardMaxWidth}>
        <ImageContentWrapper $isHorizontal={$isHorizontal}>
          <Link href={link}>
            <ImageContainer $isHorizontal={$isHorizontal}>
              <JobImage src={image} width={587} height={246} alt="Yulifers hard at work" />
            </ImageContainer>
          </Link>
          <div>
            <PillWrap>
              <Pill color="purple" text={department} />
            </PillWrap>
            <Link href={link}>
              <Title $isHorizontal={$isHorizontal}>{title}</Title>
            </Link>
            <Description>{description}</Description>
          </div>
        </ImageContentWrapper>
      </Container>
    </>
  );
};

const Container = styled.div<{
  $cardMaxWidth: string;
}>`
  max-width: ${(p) => (p.$cardMaxWidth ? p.$cardMaxWidth : "100%")};

  ${tablet} {
    max-width: 100%;
  }
`;

const ImageContentWrapper = styled.div<{ $isHorizontal?: boolean }>`
  ${(p) =>
    p.$isHorizontal
      ? ` 
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 3.75rem;

    ${tablet} {
      column-gap: 2rem;
    }

    ${mobile} {
      grid-template-columns: 1fr;
    }
  `
      : `grid-template-columns: 1fr;`}
`;

const ImageContainer = styled.div<{ $isHorizontal?: boolean }>`
  padding-bottom: ${(p) => (p.$isHorizontal ? "0" : "1.5rem")};

  ${mobile} {
    padding-bottom: 1.5rem;
  }
`;

const Title = styled(P1)<{ $isHorizontal?: boolean }>`
  padding: 0rem;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0.075rem;
  color: ${color.B1100};
  padding-top: ${(p) => (p.$isHorizontal ? "0.75rem" : "1.5rem")};

  @media only screen and (max-width: 80rem) {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  ${mobile} {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-top: 1.5rem;
  }
`;

const Description = styled(P1)`
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: 0.075rem;
  padding-top: 0.75rem;
  color: ${color.B1100};

  ${mobile} {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

const JobImage = styled(Image)`
  border: 0.125rem solid ${color.B240};
  border-radius: 0.5rem;
  box-shadow: 0.5rem 0.5rem 0 ${color.B220};
  width: 100%;
  object-fit: cover;
  transition: 200ms ease-out;
  &:hover {
    transform: translateY(-1rem);
    box-shadow: 0.9rem 0.9rem 0px ${color.B220};
  }

  ${tablet} {
    max-height: 22rem;
  }

  ${mobile} {
    max-height: 14rem;
  }
`;

const PillWrap = styled.div`
  display: flex;
`;
