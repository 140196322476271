import { FC, PropsWithChildren } from "react";
import styled from "styled-components";
import { color, fonts } from "../../styles";

interface PillProps {
  color?: "purple" | "grey";
  noBackground?: boolean;
  text: string;
  isInverted?: boolean;
}

export const Pill: FC<PillProps> = ({
  text,
  color = "purple",
  noBackground = false,
  isInverted = false,
}) => (
  <Wrapper color={isInverted ? "purple" : color} $noBackground={noBackground}>
    {" "}
    {text}
  </Wrapper>
);

const Wrapper = styled.div<{ color: string; $noBackground: boolean }>`
  font-family: ${fonts.body};
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.075rem;
  color: ${(p) => (p.color === "purple" ? color.S3100 : color.B1100)};
  border: 1px solid ${color.B240};
  padding: 0 1rem;
  ${(p) => (p.$noBackground ? "" : `background: ${p.color === "purple" ? color.S310 : "#F2F2FA"}`)};
  border-radius: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
