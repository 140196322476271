import styled from "styled-components";

interface VideoPlayerProps {
  webm?: string;
  mp4?: string;
  width?: string | number;
  height?: string | number;
  autoPlay?: boolean;
  muted?: boolean;
  borderRadius?: string;
  center?: boolean;
  $maxWidth?: string | number;
}

export function VideoPlayer({
  webm,
  mp4,
  width,
  height,
  autoPlay = true,
  muted = true,
  borderRadius = "0.5rem",
  center = false,
  $maxWidth
}: VideoPlayerProps) {
  return (
    <Video
      borderRadius={borderRadius}
      width={width}
      height={height}
      loop
      autoPlay={autoPlay}
      muted={muted}
      center={center}
      $maxWidth={$maxWidth}
    >
      {webm ? <source src={webm} type="video/webm" /> : null}
      {mp4 ? <source src={mp4} type="video/mp4" /> : null}
      Your browser does not support the video tag.
    </Video>
  );
}

const Video = styled.video<VideoPlayerProps>`
  border-radius: ${(p) => p.borderRadius};
  width: ${(p) => (p.width ? p.width : "100%")};
  height: ${(p) => (p.width ? p.height : "100%")};
  display: ${(p) => (p.center ? "flex" : "block")};
  justify-content: ${(p) => (p.center ? "center" : "flex-start")};
  max-width: ${(p) => (p.$maxWidth ? p.$maxWidth : "100%")}
`;
