import { FC, ComponentPropsWithoutRef } from "react";
import styled from "styled-components";
import Image from "next/image";

type NextImageProps = ComponentPropsWithoutRef<typeof Image>;

interface StyledImageProps {
  position?: string;
  maxWidth?: string;
  mandateHeight?: string;
}

type ResponsiveNextImage = NextImageProps & StyledImageProps;

/**
 * By default, the responsive version of the next/image component has a `position` of `absolute`. However, this can make it difficult to work with, as we lose useful static behavior such as automatic height scaling. This component overrides `absolute`, while maintaining the other benefits of next/image.
 */
export const ResponsiveNextImage: FC<ResponsiveNextImage> = ({
  fill = true,
  position,
  maxWidth,
  alt,
  quality,
  sizes,
  mandateHeight,
  ...props
}) => (
  <Wrapper mandateHeight={mandateHeight} maxWidth={maxWidth} position={position}>
    <Image
      {...props}
      alt={alt}
      fill={fill}
      sizes={
        sizes || maxWidth
          ? `(max-width: ${maxWidth}) 100vw,
        ${maxWidth}`
          : undefined
      }
      quality={quality || 50}
    />
  </Wrapper>
);

// !important is required to override the default next/image styles
const Wrapper = styled.div<{ maxWidth?: string; position?: string; mandateHeight?: string }>`
  position: relative;
  ${(p) => (p.maxWidth ? `max-width: ${p.maxWidth}` : "")};
  ${(p) => (p.mandateHeight ? `height: ${p.mandateHeight}` : "")};
  img {
    position: ${(p) => p.position || "static"} !important;
  }
`;
