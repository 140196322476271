import { FC, ComponentPropsWithoutRef } from "react";
import styled from "styled-components";
import Image from "next/image";
import { tablet } from "components/templates/Breakpoints";

type NextImageProps = ComponentPropsWithoutRef<typeof Image>;

export const ResponsiveZigZagImage: FC<NextImageProps> = ({
  alt,
  quality = 75,
  width = 500,
  height = 500,
  ...props
}) => <ResponsiveImage {...props} alt={alt} width={width} height={height} quality={quality} />;

const ResponsiveImage = styled(Image)`
  border-radius: 0.5rem;

  ${tablet} {
    width: 100%;
    height: auto;
  }
`;
